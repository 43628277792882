import { IColumnFilterValue, ISorterValue } from "@coreui/angular-pro";

export type PLTFieldErrors = {
  message: string;
  code_error: number;
  key: string;
}[];

export enum CodeError {
  Unauthorized = 401,
  Forbidden = 403,
  InternalError = 500,
}

export type StringOrNull = string | null;
export type NumberOrNull = number | null;
export type DateOrNull = Date | string | null;
export type BooleanOrNull = boolean | null;

export type ErrorMessage = { [key: string]: { [errorKey: string]: string } };

export enum StorageKeys {
  session_token = 'session-token',
  session_condo = 'session-condo',
}

export enum UserRole {
  plt = 'PLT',
  admin = 'ADMIN',
  worker = 'WORKER',
}

export type RequiredProperties<T> = {
	[P in keyof T]-?: T[P];
}


export type SelectOption = {
  label: string;
  value: string | number;
};

export interface IParams {
  activePage?: number;
  columnFilterValue?: IColumnFilterValue;
  itemsPerPage?: number;
  loadingData?: boolean;
  sorterValue?: ISorterValue;
  totalPages?: number;
}


export interface IMutationInput<T> {
  input: T;
}


export enum EPaymentMethod {
  bank = "Banco",
  cash = "Efectivo",
  card = "Tarjeta",
  transfer = "Transferencia",
  check = "Cheque",
  adjustment = "Ajuste",
  deposit = "Deposito",
  redcompra = "Redcompra",
}
